import * as React from "react"

import { Link } from "gatsby"

const NewsListItem = ({ item }) => {
	const { permalink, authorizedOn, name } = item
	const linkUrl = `/news/${permalink}`

	return (
		<div className="sm:flex py-4 border-t border-opacity-80">
			<div>
				<h4 className="text-lg font-bold">
					<Link to={linkUrl}>{name}</Link>
				</h4>
				<p className="mt-1">
					{new Intl.DateTimeFormat("en-AU", {
						timeZone: "Australia/NSW",
						year: "numeric",
						month: "long",
						day: "2-digit",
					}).format(new Date(authorizedOn))}
				</p>
			</div>
		</div>
	)
}

const RelatedNewsList = ({ list }) => {
	return (
		<>
			<h3 className="text-2xl font-normal text-gray-500 text-center md:text-left">
				Related News
			</h3>
			<ul role="list" className="divide-y divide-gray-200">
				{list.map(item => (
					<li key={item.id} className="px-4 py-4 sm:px-0">
						<NewsListItem item={item} />
					</li>
				))}
			</ul>
		</>
	)
}

export default RelatedNewsList
