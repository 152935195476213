import * as React from "react"

import { Link, graphql, useStaticQuery } from "gatsby"

const EventListItem = ({ item }) => {
	const { permalink, authorizedOn, name } = item
	const linkUrl = `/news/${permalink}`

	return (
		<div className="sm:flex py-4">
			<div>
				<h4 className="text-lg font-bold">
					<Link to={linkUrl}>{name}</Link>
				</h4>
				<p className="mt-1">
					{new Intl.DateTimeFormat("en-AU", {
						timeZone: "Australia/NSW",
						year: "numeric",
						month: "long",
						day: "2-digit",
					}).format(new Date(authorizedOn))}
				</p>
			</div>
		</div>
	)
}
const RenderedEventList = ({ list }) => {
	return (
		<>
			<h3 className="text-2xl font-regular text-gray-500 text-center md:text-left">
				Upcoming Events
			</h3>
			<ul role="list" className="divide-y divide-gray-200">
				{list.map(item => (
					<li key={item.id} className="px-4 py-4 sm:px-0">
						<EventListItem item={item} />
					</li>
				))}
			</ul>
		</>
	)
}

const UpcomingEvents = () => {
	const data = useStaticQuery(graphql`
		{
			allNewsItem(sort: { fields: authorizedOn, order: DESC }, limit: 3) {
				nodes {
					name
					permalink
					authorizedOn
					newsId
					mediaType
					thumbnailImages {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
			}
		}
	`)
	return <RenderedEventList list={data.allNewsItem.nodes} />
}

export default UpcomingEvents
